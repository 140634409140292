import React, { useState,useEffect } from 'react';
import {Document, Page, View,Text, PDFViewer,Image, StyleSheet, pdf} from '@react-pdf/renderer';
import ImagenQR from '../src/tools/imagenQR.jsx';
import Logo from '../src/imagenes/logoISePGoyenechea.png'
import logoProvincia from '../src/imagenes/logoProvincia2024.jpg'
import textoIsep from '../src/imagenes/textoIsep2024.jpg'
import axios from 'axios';
import DameToken from '../src/tools/dameToken.js'
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';


const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return windowSize;
  };
  function Constancia() {
    const { width, height } = useWindowSize();
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    const [muestro,setMuestro]=useState(false)
    const [datosVarios,setDatosVarios]=useState(false)
    const consulta = useQuery();
    const certificadoModel=(curso, certificado)=>
      {
       
      const certificadoFinal={
      "dniCertificado":certificado.dniCertificado,
      "apellidoNombre":certificado.apellidoNombre,
     
      "tituloCertificado":curso.tituloCertificado,
      "ciudadCertificado":curso.ciudadCertificado,
      "fechaciudadCertificado":curso.fechaciudadCertificado,
      "organizadoPor":curso.organizadoPor,
      "tipoCapacitacion":curso.tipoCapacitacion,
      "fechaRealizado":curso.fechaRealizado,
      "escudo1":curso.escudo1,
      "escudo2":curso.escudo2,
      "fechacursado":curso.fechacursado,
      "cantidadHoras":curso.cantidadHoras,
      "tipoHoras":curso.tipoHoras,
      "sedeCertificado":certificado.sedeCertificado,
      "resolucionCertificado":curso.resolucionCertificado,
      "resolucionJPP":curso.resolucionJPP,

      "grado":certificado.grado,
      "calidadDe":certificado.calidadDe,
      "reginterno":curso.reginterno,
      "cantidadFirmas":curso.cantidadFirmas,
      "firma1":curso.firma1,        
      "firmante1":curso.firmante1,
      "firmante1jer":curso.firmante1jer,
      "firmante1cargo":curso.firmante1cargo, 
      "firma2":curso.cantidadFirmas>1?curso.firma2:"",
      "firmante2":curso.cantidadFirmas>1?curso.firmante2:"",
      "firmante2jer":curso.cantidadFirmas>1?curso.firmante2jer:"",
      "firmante2cargo":curso.cantidadFirmas>1?curso.firmante2cargo:"",  
      "firma3":curso.cantidadFirmas>2?curso.firma3:"", 
      "firmante3":curso.cantidadFirmas>2?curso.firmante3:"",
      "firmante3jer":curso.cantidadFirmas>2?curso.firmante3jer:"",
      "firmante3cargo":curso.cantidadFirmas>2?curso.firmante3cargo:"",
      "firma4":curso.cantidadFirmas>3?curso.firma4:"",
      "firmante4":curso.cantidadFirmas>3?curso.firmante4:"",
      "firmante4jer":curso.cantidadFirmas>3?curso.firmante4jer:"",
      "firmante4cargo":curso.cantidadFirmas>3?curso.firmante4cargo:"",
      "firma5":curso.cantidadFirmas>4?curso.firma5:"",
      "firmante5":curso.cantidadFirmas>4?curso.firmante5:"",
      "firmante5jer":curso.cantidadFirmas>4?curso.firmante5jer:"",
      "firmante5cargo":curso.cantidadFirmas>4?curso.firmante5cargo:""    
      }
      setDatosVarios(certificadoFinal)
      setMuestro(true)
      }
   


  function fechaFormal() {
    const meses = [
      "enero", "febrero", "marzo", "abril", "mayo", "junio", 
      "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
  
    const hoy = new Date();
    const dia = hoy.getDate();
    const mes = meses[hoy.getMonth()];
    const anio = hoy.getFullYear();
  
    return `a los ${dia} días del mes de ${mes} del año ${anio}`;
  }
  function strigAFecha(dateString) {
  
    const date = new Date(dateString);
  
  
    if (isNaN(date)) {
        throw new Error('Fecha inválida');
    }
  
    return date;
  }
  
    
  const direccion="https://certificados.isepsantafe.edu.ar?ni="+consulta.get("ni")+"&curso="+consulta.get('curso')+"&Ra@="+DameToken()
  
  
  
  const obtieneCertificado=async()=>{
    const client = axios.create({
        baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/certificados/api-devuelveCertificados?ni="+consulta.get("ni")+"&curso="+consulta.get('curso')+"&Ra@="+DameToken()
        });
        try {
         const datos= await client.post('/')
         if(datos)
          {
         
         certificadoModel(datos.data.Curso,datos.data.cursante)
         setMuestro(true)
         }
          }
        catch (error) {}
   
  }
  
  
  
  useEffect(() => {
  
    obtieneCertificado()
    return () => {
      
    };
  }, []);
  
  
  function verificarString(cadena) {
   
    if (cadena>6000000) {
      return "DNI";
    } else {
      return "N.I.";
    } 
  }

  const hoy=fechaFormal()

  const text=`En la ciudad de Recreo, Departamento La Capital, Provincia de Santa Fe a los ${hoy}, el Director General del Instituto de Seguridad Pública, Director de Policía Lic. Mario Goyenechea, certifica que el ${datosVarios.grado?datosVarios.grado:"ciudadano"} ${datosVarios.apellidoNombre} ${ verificarString(datosVarios.dniCertificado)+" Nro: " + datosVarios.dniCertificado} ha participado en calidad de “${datosVarios.calidadDe}” en el ${datosVarios.tipoCapacitacion} : “${datosVarios.tituloCertificado}”, avalada por la Subsecretaría de Formación y Carrera Policial dependiente del Ministerio de Justicia y Seguridad de la Provincia, validada mediante Resolución de la Dirección General del I.Se.P. Nº${datosVarios.resolucionCertificado}, y autorización pertinente del Sr. Jefe de Policía de la Pcia. a través de Resolución J.P.P. Nº${datosVarios.resolucionJPP} D.P. (D-1). Dicha capacitación fue organizada por ${datosVarios.organizadoPor} y se llevó a cabo el ${datosVarios.fechaRealizado} con una duración de ${datosVarios.cantidadHoras}. Se extiende la presente certificación a fin de ser presentado por ante quien corresponda.----------------------------------------------------------`
  const MyDocument = () => (
    <Document  title={"Certificado "+datosVarios.tituloCertificado + " "+ datosVarios.apellidoNombre} onLoadError={console.error}>
          <Page size="A4" >
          <View style={{ width:"100%", marginTop:"20px",marginLeft:"10px",padding:"1px"}}>
          <View id="Logos"style={{ width:"100%", marginTop:"20px",  padding:"1px"}}>
          <Image style={{width:"150px", position:"relative",marginLeft:"10px" }}src={logoProvincia}/> 
         
          <Image style={{width:"150px", position:"relative", alignSelf:"flex-end", marginTop:"-17px", marginRight:"10px"}} src={textoIsep}/>
          <Image style={{width:"100px", position:"relative", marginLeft:"240px",marginTop:"20px"}} src={Logo}/>
        
         </View>
         <Text style={{textAlign:"center",margin:"20px",paddingTop:"1px",fontWeight:"bold"}}>CERTIFICADO DE CAPACITACIÓN</Text>
          
          <View className='datosPersonales_legajo' style={{textAlign:"justify",marginLeft:"50px",marginRight:"50px",paddingTop:"1px",fontSize:"12px",lineHeight: "2"}}>
  

      <Text >{text}</Text>

      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"10px" ,  position:"relative",  marginTop:"80px", marginLeft:"350px",lineHeight: "1.1"}}>
   
      <Image style={{width:"140px", position:"absolute", marginLeft:"15px",marginTop:"-55px"}}src={datosVarios.firma1}/> 
  
      <Text >{"Lic. Goyenechea Mario"}</Text>
      <Text style={{textAlign:"center",}}>{"Director de Policía"}</Text>
      <Text >{"Director General ISeP"}</Text>
      </View>
      <View id="QR"style={{width:"150px", position:"relative", marginLeft:"00px",marginTop:"-80px"}}>       
      <ImagenQR direccion={direccion} tamaño="210"/></View>
      </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"6px" ,  position:"relative",  marginTop:"80px",lineHeight: "1.1"}}>
      <Text >{"isep@isepsantafe.edu.ar"}</Text>      </View>
      </View>
          </Page>
        </Document>
  );
  
    const generatePdfDocument = async () => {
     try {
      const nombreArchivo="Certificado "+datosVarios.tituloCertificado + " "+ datosVarios.apellidoNombre
      const doc =<MyDocument/>
      const blob = await pdf(doc).toBlob();
      saveAs(blob, nombreArchivo);
     } catch (error) {
      
     } 
    };
  // Componente principal del visor PDF
  return (<>
  {<>
  
    
        {width<1250?<><div style={{textAlign:"center"}}>
          <img src={Logo} style={{width:"100px"}}></img>
          <h1>Descargue su certificado {datosVarios.apellidoNombre}</h1>
          <button className="btn btn-primary"onClick={generatePdfDocument}>DESCARGAR</button></div></>:
          <>
          <PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"}} >
        <MyDocument />
        </PDFViewer></>}
  
    
  </>}
  
        
        </>
  
      );
    }
  export default Constancia
  